// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 34 29' {...props}>
    <mask id='path-1-inside-1_7782_24574' fill='white'>
      <path d='M1.35924 28.9167C0.607578 28.9167 -0.00387611 28.2583 1.85022e-05 27.4542C1.85022e-05 26.65 0.611473 26 1.36313 26L32.6408 26.0833C33.3924 26.0833 34.0039 26.7417 34 27.5458C34 28.35 33.3885 29 32.6369 29L1.35924 28.9167Z' />
      <path d='M3 8C3 6.89543 3.89543 6 5 6H9C10.1046 6 11 6.89543 11 8V22C11 23.1046 10.1046 24 9 24H5C3.89543 24 3 23.1046 3 22V8Z' />
      <path d='M13 2C13 0.89543 13.8954 0 15 0H19C20.1046 0 21 0.895431 21 2V22C21 23.1046 20.1046 24 19 24H15C13.8954 24 13 23.1046 13 22V2Z' />
      <path d='M23 14C23 12.8954 23.8954 12 25 12H29C30.1046 12 31 12.8954 31 14V22C31 23.1046 30.1046 24 29 24H25C23.8954 24 23 23.1046 23 22V14Z' />
    </mask>
    <path d='M1.35924 28.9167L1.36723 25.9167H1.35924V28.9167ZM1.85022e-05 27.4542L2.99998 27.4687L3.00002 27.4614V27.4542H1.85022e-05ZM1.36313 26L1.37113 23H1.36313V26ZM32.6408 26.0833L32.6328 29.0833H32.6408V26.0833ZM34 27.5458L31 27.5313L31 27.5386V27.5458H34ZM32.6369 29L32.6289 32H32.6369V29ZM1.35924 25.9167C2.46105 25.9167 3.00318 26.8079 2.99998 27.4687L-2.99995 27.4396C-3.01094 29.7088 -1.24589 31.9167 1.35924 31.9167V25.9167ZM3.00002 27.4542C3.00002 28.1243 2.44512 29 1.36313 29V23C-1.22218 23 -2.99998 25.1756 -2.99998 27.4542H3.00002ZM1.35514 29L32.6328 29.0833L32.6488 23.0833L1.37113 23L1.35514 29ZM32.6408 29.0833C31.539 29.0833 30.9968 28.1921 31 27.5313L36.9999 27.5604C37.0109 25.2912 35.2459 23.0833 32.6408 23.0833V29.0833ZM31 27.5458C31 26.8756 31.5549 26 32.6369 26V32C35.2222 32 37 29.8244 37 27.5458H31ZM32.6449 26L1.36723 25.9167L1.35125 31.9167L32.6289 32L32.6449 26ZM5 9H9V3H5V9ZM8 8V22H14V8H8ZM9 21H5V27H9V21ZM6 22V8H0V22H6ZM5 21C5.55228 21 6 21.4477 6 22H0C0 24.7614 2.23858 27 5 27V21ZM8 22C8 21.4477 8.44771 21 9 21V27C11.7614 27 14 24.7614 14 22H8ZM9 9C8.44771 9 8 8.55229 8 8H14C14 5.23858 11.7614 3 9 3V9ZM5 3C2.23858 3 0 5.23858 0 8H6C6 8.55229 5.55228 9 5 9V3ZM15 3H19V-3H15V3ZM18 2V22H24V2H18ZM19 21H15V27H19V21ZM16 22V2H10V22H16ZM15 21C15.5523 21 16 21.4477 16 22H10C10 24.7614 12.2386 27 15 27V21ZM18 22C18 21.4477 18.4477 21 19 21V27C21.7614 27 24 24.7614 24 22H18ZM19 3C18.4477 3 18 2.55228 18 2H24C24 -0.761424 21.7614 -3 19 -3V3ZM15 -3C12.2386 -3 10 -0.761425 10 2H16C16 2.55229 15.5523 3 15 3V-3ZM25 15H29V9H25V15ZM28 14V22H34V14H28ZM29 21H25V27H29V21ZM26 22V14H20V22H26ZM25 21C25.5523 21 26 21.4477 26 22H20C20 24.7614 22.2386 27 25 27V21ZM28 22C28 21.4477 28.4477 21 29 21V27C31.7614 27 34 24.7614 34 22H28ZM29 15C28.4477 15 28 14.5523 28 14H34C34 11.2386 31.7614 9 29 9V15ZM25 9C22.2386 9 20 11.2386 20 14H26C26 14.5523 25.5523 15 25 15V9Z' fill='#6988BE' mask='url(#path-1-inside-1_7782_24574)' />
  </Svg>
);

export default Icon;
