// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 77.69 77.69' {...props}>
    <path fill='#fff' className='a' d='M64.63,46.12c-.75,1.23-2.15,1.41-4.26,1.66-.65.09-1.39.17-2.23.3a1,1,0,0,0-.68.41,1,1,0,0,0-.14.76,2.9,2.9,0,0,0,2.43,2.57A3.5,3.5,0,0,1,62,54.17a3.44,3.44,0,0,1-.68,3.16c-1.52,1.75-4.41,3.83-9.58,3.83-.48,0-1,0-1.46-.06-1-.07-2.1-.19-3.16-.34h-.05l-.12,0-2.13-.32a52.19,52.19,0,0,0-7.71-.78,19.66,19.66,0,0,0-4.39.45,7.44,7.44,0,0,0-6,7.81,7.68,7.68,0,0,0,6.75,7.57s.17,1.88-.27,1.82l-.5-.08a38.49,38.49,0,0,1-14.89-5.7,39.42,39.42,0,0,1-7.56-6.37c-.37-.41-.73-.82-1.09-1.24A38.79,38.79,0,0,1,13,9.85l.14-.13c0-.05.19.11.23.08.32-.28,1.1,1.17,1.1,1.17a1.4,1.4,0,0,0-.41,1.51,1.41,1.41,0,0,0,1.23,1l.27,0a14.66,14.66,0,0,1,3-3.69A5.1,5.1,0,0,1,22,8.5a5,5,0,0,1,3.5,1.42,12.42,12.42,0,0,1,2,2.62,23.14,23.14,0,0,1,2.41-.12,40.86,40.86,0,0,1,6.08.5,12.32,12.32,0,0,1,3-2.46,4.17,4.17,0,0,1,2.16-.62,5,5,0,0,1,4.21,2.81,18.15,18.15,0,0,1,2.1,5.13c5,4.27,12.82,14,13,17a16,16,0,0,0,1.64,3.29,35.58,35.58,0,0,1,2.67,5.39A3,3,0,0,1,64.63,46.12Z' />
    <path fill='#dfe3e4' className='b' d='M62.08,55.38c0,5.24-6.35,8.26-15.56,7.31-6.18-.65-14.65-.25-16.27,2.92-2.76,5.4,4.32,11.55,4,11.53-.56-.06-.51.23-1.06.15l-.5-.08a38.49,38.49,0,0,1-14.89-5.7c-.59-.39-1.18-.78-1.75-1.2A30,30,0,0,1,6.41,58.76c-6.56-14.26,2.86-24,9.22-26.18C6,40.71,10.59,54.48,20.3,57a16.2,16.2,0,0,0,9.09-.22,14.48,14.48,0,0,0,5.43-2.58s15,4.54,20.24,3.56h0c.31-.07.61-.17.88-.26,3.12-1,4.59-3,2.31-4.29l-.07,0,.19-.14C59.17,52.53,62.08,50.85,62.08,55.38Z' />
    <path fill='#ff4f24' className='c' d='M77.69,38.29a38.68,38.68,0,0,1-9.36,25.85l0,0c-.44.51-.89,1-1.35,1.48l0,0a38.81,38.81,0,0,1-28.08,12,40.12,40.12,0,0,1-5.63-.4c-10.58-1.54-11.32-16.69-.89-19,5.16-1.13,10.16,0,14.88.63l.18,0q1.53.21,3,.33c5.48.39,8.25-1.69,9.5-3.13a1.61,1.61,0,0,0-.74-2.56,4.71,4.71,0,0,1-3.67-3.95,2.82,2.82,0,0,1,2.35-3.37c4.32-.65,5.82-.35,5.15-2.19-1.29-3.58-4.32-7.47-4.42-9.18-.13-2-7.69-12-12.84-16.11a15.57,15.57,0,0,0-2-5.2c-.91-1.53-2.32-2.44-3.86-1.53a12.52,12.52,0,0,0-3.23,2.86,34.8,34.8,0,0,0-10.23-.39,11,11,0,0,0-2.27-3.28,3.19,3.19,0,0,0-4.36-.07,12.61,12.61,0,0,0-3.12,4.28l-1.53-.14a3.28,3.28,0,0,1-1.85-5.72,38.85,38.85,0,0,1,64.4,28.7Z' />
    <path fill='#ff4f24' className='c' d='M37.4,35.36c2.28,4.5,4.63,4,7.32,3,0,0-1.58-7.79-7.32-3Z' />
    <path opacity='0.1' className='d' d='M68.3,64.18A10.54,10.54,0,0,0,67,65.66l0,0a38.81,38.81,0,0,1-28.08,12,40.12,40.12,0,0,1-5.63-.4c-10.58-1.54-11.32-16.69-.89-19,5.16-1.13,10.16,0,14.88.63C20.06,55.09,41.59,88.58,68.3,64.18Z' />
    <path fill='#dfe3e4' className='b' d='M24.47,16.2c-1.94.3-4,.67-5.73,1.08A8.48,8.48,0,0,1,21,13.55a1.29,1.29,0,0,1,1.39-.05A8.1,8.1,0,0,1,24.47,16.2Z' />
  </Svg>
);

export default Icon;
