// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 16 16' {...props}>
    <path fillRule='evenodd' clipRule='evenodd' d='M5.59908 1.6621V5.58543H1.66616V1.6621H5.59908ZM6.43216 0H0.833081C0.372949 0 0 0.37204 0 0.831051V6.41648C0 6.87549 0.372949 7.24753 0.833081 7.24753H6.43216C6.89229 7.24753 7.26524 6.87549 7.26524 6.41648V0.831051C7.26524 0.37204 6.8921 0 6.43216 0ZM5.59908 9.89607V13.8194H1.66616V9.89607H5.59908ZM6.43216 8.23397H0.833081C0.372949 8.23397 0 8.60601 0 9.06502V14.6505C0 15.1095 0.372949 15.4815 0.833081 15.4815H6.43216C6.89229 15.4815 7.26524 15.1095 7.26524 14.6505V9.06502C7.26524 8.60601 6.8921 8.23397 6.43216 8.23397ZM13.8365 1.6621V5.58543H9.90358V1.6621H13.8365ZM14.6696 0H9.0705C8.61037 0 8.23742 0.37204 8.23742 0.831051V6.41648C8.23742 6.87549 8.61037 7.24753 9.0705 7.24753H14.6696C15.1297 7.24753 15.5027 6.87549 15.5027 6.41648V0.831051C15.5027 0.37204 15.1297 0 14.6696 0Z' />
    <path fillRule='evenodd' clipRule='evenodd' d='M15.5994 14.0806L14.605 13.0887C14.8537 12.6094 14.994 12.0654 14.994 11.4885C14.994 9.56179 13.4284 8 11.497 8C9.56561 8 8 9.56179 8 11.4885C8 13.4152 9.56561 14.977 11.497 14.977C12.1313 14.977 12.7263 14.8084 13.2391 14.5139L14.202 15.4746C14.3942 15.6663 14.6474 15.762 14.9008 15.762C15.154 15.762 15.4072 15.6663 15.5994 15.4746C15.9836 15.0912 15.9836 14.464 15.5994 14.0806ZM11.497 13.3149C10.4874 13.3149 9.66616 12.4956 9.66616 11.4885C9.66616 10.4814 10.4874 9.6621 11.497 9.6621C12.5066 9.6621 13.3278 10.4814 13.3278 11.4885C13.3278 12.4956 12.5066 13.3149 11.497 13.3149Z' />
  </Svg>
);

export default Icon;
