// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 60 60' {...props}>
    <path d='m46.096 18.348-14.279-8.25a3.673 3.673 0 0 0-3.692 0l-14.279 8.25A3.67 3.67 0 0 0 12 21.549v16.5a3.67 3.67 0 0 0 1.846 3.202l14.308 8.25a3.674 3.674 0 0 0 3.692 0l14.308-8.25A3.67 3.67 0 0 0 48 38.05v-16.5c-.058-1.327-.75-2.539-1.904-3.202Zm-5.365 7.211-9.808 5.539V42.26c-.029.577-.144 1.01-.634 1.125-.231.144-.462.202-.722.202h-.115a1.386 1.386 0 0 1-1.385-1.385V30.78c0-.086 0-.173.03-.26l-8.77-4.96c-.664-.376-1.039-1.212-.664-1.876.375-.663 1.154-.894 1.789-.52L30 28.705l9.577-5.54c.663-.374 1.385-.143 1.76.52.374.635.057 1.5-.606 1.875Z' />
  </Svg>
);

export default Icon;
