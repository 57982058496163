// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 65 65' {...props}>
    <path d='m22.457 44.818 3.659-.499c.782-1.966 2.51-2.95 5.186-2.95 4.014 0 5.852-.631 8.172.336 1.333.557 1.09 1.72.289 2.46-1.027.946-4.298-.174-4.338 1.34-.041 1.517 1.53 1.001 2.902 1.001h4.06l6.017-4.398a.853.853 0 0 1 1.33.905L49.32 44.6a2.167 2.167 0 0 1-.797 1.186l-6.473 4.851H28.58l-1.505 1.434a2.165 2.165 0 0 1-3.186-.216l-2.831-3.538a2.167 2.167 0 0 1 1.4-3.5Zm.293-1.718v-7.35a1.083 1.083 0 0 0-2.167 0v7.645l2.167-.295Zm6.5-3.621v-5.896a1.083 1.083 0 0 0-2.167 0v6.86c.604-.444 1.326-.766 2.167-.964Zm6.5-.403v-7.66a1.083 1.083 0 0 0-2.167 0v7.779c.796-.034 1.51-.08 2.167-.12Zm6.5 1.382V27.083a1.083 1.083 0 0 0-2.167 0V39.26c.373.094.748.219 1.135.38.419.175.74.473 1.032.818Zm6.5-.667V22.75a1.083 1.083 0 0 0-2.167 0v18.522l1.82-1.33a.852.852 0 0 1 .347-.151ZM45.746 16.581A36.256 36.256 0 0 1 15.708 32.5a1.083 1.083 0 0 1 0-2.167c11.451 0 21.916-5.684 28.21-14.914l-1.797.618a1.084 1.084 0 1 1-.704-2.048l4.096-1.412a1.08 1.08 0 0 1 1.377.672l1.411 4.097a1.084 1.084 0 0 1-2.048.707l-.507-1.472Z' />
  </Svg>
);

export default Icon;
