// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 20 20' {...props}>
    <path d='M9.504 20c4.343 0 7.934-3.238 8.495-7.429a.956.956 0 0 0-1.066-1.066c-4.19.562-7.429 4.152-7.429 8.495ZM3.41 8.81a2.382 2.382 0 0 0 3.733 1.961l-.019.181a2.382 2.382 0 0 0 4.762 0l-.019-.18c.381.266.848.418 1.353.418a2.382 2.382 0 0 0 2.38-2.38c0-.953-.562-1.762-1.361-2.143a2.373 2.373 0 0 0 1.361-2.143 2.382 2.382 0 0 0-3.733-1.962l.02-.181a2.382 2.382 0 0 0-4.763 0l.02.18a2.347 2.347 0 0 0-1.353-.418 2.382 2.382 0 0 0-2.381 2.38c0 .953.562 1.763 1.362 2.144A2.373 2.373 0 0 0 3.409 8.81Zm6.095-4.524a2.382 2.382 0 1 1-.002 4.763 2.382 2.382 0 0 1 .002-4.763ZM1.01 12.57C1.571 16.761 5.161 20 9.504 20c0-4.343-3.238-7.933-7.428-8.495a.956.956 0 0 0-1.067 1.066Z' fill='url(#a)' />
    <path d='M9.504 20c4.343 0 7.934-3.238 8.495-7.429a.956.956 0 0 0-1.066-1.066c-4.19.562-7.429 4.152-7.429 8.495ZM3.41 8.81a2.382 2.382 0 0 0 3.733 1.961l-.019.181a2.382 2.382 0 0 0 4.762 0l-.019-.18c.381.266.848.418 1.353.418a2.382 2.382 0 0 0 2.38-2.38c0-.953-.562-1.762-1.361-2.143a2.373 2.373 0 0 0 1.361-2.143 2.382 2.382 0 0 0-3.733-1.962l.02-.181a2.382 2.382 0 0 0-4.763 0l.02.18a2.347 2.347 0 0 0-1.353-.418 2.382 2.382 0 0 0-2.381 2.38c0 .953.562 1.763 1.362 2.144A2.373 2.373 0 0 0 3.409 8.81Zm6.095-4.524a2.382 2.382 0 1 1-.002 4.763 2.382 2.382 0 0 1 .002-4.763ZM1.01 12.57C1.571 16.761 5.161 20 9.504 20c0-4.343-3.238-7.933-7.428-8.495a.956.956 0 0 0-1.067 1.066Z' fill='url(#b)' />
    <path d='M9.504 20c4.343 0 7.934-3.238 8.495-7.428a.956.956 0 0 0-1.066-1.067c-4.19.562-7.429 4.152-7.429 8.495ZM1.01 12.572C1.571 16.762 5.161 20 9.504 20c0-4.343-3.238-7.933-7.428-8.495a.956.956 0 0 0-1.067 1.067Z' fill='url(#c)' />
    <defs>
      <linearGradient id='a' x1='18.008' y1='0' x2='1' y2='0' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF6DD4' />
        <stop offset='1' stopColor='#FF4479' />
      </linearGradient>
      <linearGradient id='b' x1='18.008' y1='0' x2='17.5' y2='11.5' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF6DD4' />
        <stop offset='1' stopColor='#FF4479' />
      </linearGradient>
      <linearGradient id='c' x1='1' y1='13.835' x2='1' y2='20' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#C1FF84' />
        <stop offset='1' stopColor='#0FEB00' />
      </linearGradient>
    </defs>
  </Svg>
);

export default Icon;
