// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 64 64' {...props} style={{ fill: 'none' }}>
    <path fillRule='evenodd' clipRule='evenodd' d='M50.1162 35.6631C50.7243 36.9874 52.5149 37.4551 53.5453 36.4247L55.435 34.535C57.3876 32.5824 60.5535 32.5824 62.5061 34.535C64.4587 36.4876 64.4587 39.6535 62.5061 41.6061L47.234 56.8781C43.5653 61.2331 38.0714 64 31.9314 64C25.5284 64 19.8282 60.9911 16.1676 56.3102L1.46451 41.607C-0.488105 39.6544 -0.488105 36.4886 1.46452 34.536C3.41714 32.5834 6.58296 32.5834 8.53558 34.536L10.3966 36.397C11.4045 37.4049 13.1543 36.9515 13.7496 35.6564C13.8672 35.4005 13.9312 35.1259 13.9312 34.8443L13.9311 11.9999C13.9311 9.23852 16.1697 6.99995 18.9311 6.99995C21.6926 6.99995 23.9311 9.23852 23.9311 11.9999L23.9311 23.5564C23.9311 24.5508 24.9498 25.2258 25.898 24.9262C26.4981 24.7365 26.9316 24.1902 26.9316 23.5608L26.9316 5.00002C26.9316 2.2386 29.1702 2.82005e-05 31.9316 2.74763e-05C34.693 2.67521e-05 36.9316 2.2386 36.9316 5.00001L36.9316 23.5612C36.9316 24.1904 37.365 24.7366 37.9649 24.9262C38.9128 25.2258 39.9312 24.551 39.9312 23.5569L39.9312 11.9999C39.9312 9.23852 42.1698 6.99995 44.9312 6.99995C47.6926 6.99995 49.9312 9.23852 49.9312 11.9999L49.9312 34.835C49.9312 35.1222 49.9964 35.4021 50.1162 35.6631Z' fill='#FD4848' />
    <path d='M47.9318 45.9999C47.9318 45.9999 40.7684 55.9999 31.9318 55.9999C23.0953 55.9999 15.9318 45.9999 15.9318 45.9999C15.9318 45.9999 23.0953 35.9999 31.9318 35.9999C40.7684 35.9999 47.9318 45.9999 47.9318 45.9999Z' fill='#D5FF5C' />
    <path d='M39.4316 46.0001C39.4316 50.142 36.074 53.4997 31.932 53.4997C27.7901 53.4997 24.4324 50.142 24.4324 46.0001C24.4324 41.8582 27.7901 38.5005 31.932 38.5005C36.074 38.5005 39.4316 41.8582 39.4316 46.0001Z' stroke='#FD4848' strokeWidth='1.00078' />
    <path d='M36.4314 46.0004C36.4314 48.4854 34.4168 50.5 31.9318 50.5C29.4467 50.5 27.4322 48.4854 27.4322 46.0004C27.4322 43.5153 29.4467 41.5007 31.9318 41.5007C34.4168 41.5007 36.4314 43.5153 36.4314 46.0004Z' stroke='#FD4848' strokeWidth='1.00078' />
    <path d='M42.4314 46.0001C42.4315 51.7988 37.7306 56.4997 31.9318 56.4997C26.1331 56.4997 21.4322 51.7989 21.4322 46.0001C21.4322 40.2013 26.1331 35.5005 31.9318 35.5005C37.7306 35.5005 42.4314 40.2013 42.4314 46.0001Z' stroke='#FD4848' strokeWidth='1.00078' />
    <path d='M45.4314 46C45.4314 53.4557 39.3874 59.4996 31.9318 59.4996C24.4761 59.4996 18.4322 53.4557 18.4322 46C18.4322 38.5444 24.4761 32.5004 31.9318 32.5004C39.3874 32.5004 45.4314 38.5444 45.4314 46Z' stroke='#FD4848' strokeWidth='1.00078' />
    <path d='M33.4317 45.9999C33.4317 46.8281 32.7603 47.4995 31.9321 47.4995C31.1038 47.4995 30.4324 46.8281 30.4324 45.9999C30.4324 45.1717 31.1038 44.5003 31.9321 44.5003C32.7603 44.5003 33.4317 45.1717 33.4317 45.9999Z' fill='#162BEB' stroke='#FD4848' strokeWidth='1.00078' />
    <ellipse cx='31.9321' cy='45.9999' rx='2' ry='2' fill='#FD4848' />
    <path d='M16.6516 46.122C16.6174 46.0781 16.5859 46.0374 16.5571 45.9999C16.5859 45.9625 16.6174 45.9218 16.6516 45.8779C16.8601 45.6103 17.1692 45.2262 17.5679 44.7645C18.366 43.8404 19.5204 42.6089 20.9451 41.3787C23.8102 38.9049 27.6948 36.5003 31.9318 36.5003C36.1689 36.5003 40.0534 38.9049 42.9185 41.3787C44.3433 42.6089 45.4977 43.8404 46.2958 44.7645C46.6945 45.2262 47.0035 45.6103 47.2121 45.8779C47.2463 45.9217 47.2778 45.9625 47.3066 45.9999C47.2778 46.0374 47.2463 46.0781 47.2121 46.122C47.0035 46.3896 46.6945 46.7737 46.2958 47.2354C45.4977 48.1595 44.3433 49.391 42.9185 50.6212C40.0534 53.095 36.1689 55.4995 31.9318 55.4995C27.6948 55.4995 23.8102 53.095 20.9451 50.6212C19.5204 49.391 18.366 48.1595 17.5679 47.2354C17.1692 46.7737 16.8601 46.3896 16.6516 46.122Z' stroke='#D5FF5C' strokeWidth='1.00078' />
  </Svg>
);

export default Icon;
