// Copyright 2018-2021 evolution.land authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => (
  <Svg viewBox='0 0 40 40' {...props}>
    <path d='M14.6094 16.8984L14.6094 13.0547C14.6094 10.0742 17.0234 7.66016 20.0039 7.66016C22.9844 7.66016 25.3984 10.0781 25.3984 13.0586L25.3984 16.9023L14.6094 16.9023L14.6094 16.8984ZM29.5664 16.9414L29.5703 16.8203L29.5703 12.5977C29.5703 10.2578 28.6406 8.01172 26.9844 6.35547C25.3281 4.69922 23.0859 3.76953 20.7422 3.76953L19.2617 3.76953C14.3867 3.76953 10.4375 7.72266 10.4375 12.5938L10.4375 16.9414C8.16406 17.2578 6.47266 19.2031 6.47266 21.5L6.47266 31.5898C6.47266 34.1328 8.53125 36.1914 11.0742 36.1914L28.9336 36.1914C31.4766 36.1914 33.5352 34.1328 33.5352 31.5898L33.5352 21.5039C33.5352 19.207 31.8438 17.2617 29.5664 16.9414ZM21.0664 27.332L21.0664 30.3398C21.0664 30.9258 20.5898 31.4023 20.0039 31.4023C19.418 31.4023 18.9414 30.9258 18.9414 30.3398L18.9414 27.332C17.6328 26.8203 16.8711 25.4492 17.1328 24.0703C17.3945 22.6875 18.5977 21.6875 20.0039 21.6875C21.4102 21.6875 22.6172 22.6875 22.875 24.0703C23.1367 25.4492 22.375 26.8203 21.0664 27.332Z' fill='white' />
  </Svg>
);

export default Icon;
